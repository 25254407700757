"use client"

import DownArrowDiv from "@/src/components/ui/downArrow";
import MovingBackgroundDiv from "@/src/components/ui/movingBackgroundDiv";
import TOMImage from "@/src/components/ui/tomImage";
import { useRef } from "react";
import { Link } from "react-router-dom";

const mainAreas = [
  {
    title: "WHO ARE WE?",
    image: "Minds1.jpg",
  },
  {
    title: "JOIN TECHNOLOGY INTERVENTION",
    image: "Minds3.jpg",
  },
  {
    title: "NURTURE THE NEXT GENERATION",
    image: "Minds4.jpg",
  },
  {
    title: "ART IS A LIFESTYLE",
    image: "Minds2.jpg",
  },
]

function MindsPage(props: {
  yScroll?: number;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <div
        ref={containerRef}
        className="font-semibold [&>div]:bg-fixed [&>div]:bg-cover [&>div]:bg-no-repeat [&>div]:full-screen [&>div]:text-2xl [&>div]:md:text-4xl [&>div]:lg:text-6xl [&>div]:flex [&>div]:items-center [&>div]:justify-center"
      >
        {mainAreas.map(
          (area, index) => {
            return (
              <MovingBackgroundDiv
                scroll={props.yScroll || 0}
                factor={0.5}
                style={{
                  backgroundImage: `url(${'images/projects/' + area.image})`,
                }}
                key={`about-${index}`}
                className="relative"
              >
                <span className="text-shadow text-white text-center">{area.title}</span>
                {index === 0 && <DownArrowDiv className="absolute bottom-10 left-[calc(50%-16px)] animate-bounce" />}
              </MovingBackgroundDiv>
            );
          }
        )}
      </div>
      <div className="py-16 px-8">
        <div className="container mx-auto">
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
            <div className="col-span-1 md:col-span-2 lg:col-span-4">
              <h2 className="text-primary font-bold text-xl md:text-2xl lg:text-4xl mb-6">
                Minds
              </h2>
              <Link
                className="flex gap-2 w-fit"
                to={"https://www.instagram.com/tonsofminds/"}
                target="_blank"
              >
                <TOMImage
                  src="logo192.png"
                  alt="Tons of Minds Limited"
                  className="h-10 w-10 "
                />
                <div className="text-sm">
                  <div className="font-bold">tonsofminds</div>
                  <div className="font-medium">MINDS EMPOWERING FUTURE</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MindsPage;
