import { EnvelopeClosedIcon, InstagramLogoIcon } from "@radix-ui/react-icons";
import { Link } from "react-router-dom";
import { cn } from "../utils/utils";

function Footer(props: { className?: string }) {
  return (
    <footer
      className={cn("flex flex-col items-center py-12 gap-2", props.className)}
    >
      <div className="flex justify-center gap-2">
        <Link
          className="group bg-transparent rounded-full text-primary-foreground hover:bg-primary p-3 md:p-4"
          to="mailto:bonjour@tonsofminds.com"
          target="_blank"
        >
          <EnvelopeClosedIcon
            color="var(--primary)"
            className="group-hover:stroke-primary-foreground"
          />
        </Link>
        <Link
          className="group bg-transparent rounded-full text-primary-foreground hover:bg-primary p-3 md:p-4"
          to="https://www.instagram.com/tonsofminds"
          target="_blank"
        >
          <InstagramLogoIcon
            color="var(--primary)"
            className="group-hover:stroke-primary-foreground"
          />
        </Link>
      </div>
      <hr className="w-[80%]" />
      <div className="font-light p-3 md:p-4">
        Copyright © 2025 Tons of Minds Limited — All right reserved
      </div>
    </footer>
  );
}

export default Footer;
